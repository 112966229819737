

























































































































import { errorNotification } from '@/includes/NotificationService'

export enum ActiveBindMethod {
  Self = 'self',
  Code = 'code'
}

import Api from '@/includes/logic/Api'
import chatConfigMixin from '@/mixins/chatConfig'
import { InputSetups } from '@/mixins/input-setups'
import BindChannelByCode, { BindChannelState } from '@/components/chat/BindChannelByCode.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

@Component({
  'mixins': [
    chatConfigMixin,
  ],
  'components': {
    ValidationObserver,
    BindChannelByCode
  },
  data() {
    return {
      ActiveBindMethod
    }
  }
})
export default class ChannelBinding extends Mixins(UseFields, InputSetups) {
  @Prop() preAlert!: string

  @Prop() postAlert!: string

  model = {
    channelName: ''
  }

  channelBindModal = false

  activeMethod = ActiveBindMethod.Self

  channelBindingLoading = false

  bindChatChannel(): void {
    this.doBindChannel(this.model.channelName)
  }

  handleChannelBind(bindData: BindChannelState): void {
    if (bindData.channel_id) {
      this.doBindChannel(bindData.channel_id.toString())
    }
  }

  doBindChannel(channel_id: string): void {
    Api.bindChatChannel('tg', {
      chat_id: parseInt(this.$store.state.chatState.chat.chat_id),
      channel: channel_id.trim()
    })
      .then(({ data }) => {
        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.$store.state.chatState.chat.binded_channel = data.chat.binded_channel
        })
      })
      .catch(errorNotification)
      .finally(() => this.channelBindModal = false)
  }

  unbindChannel(): void {
    this.$confirm({
      title: this.$t('channel_binding_unbind_popup_title').toString(),
      content: this.$t('channel_binding_sure').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.channelBindingLoading = true

        Api.unbindChatChannel('tg', { chat_id: this.$store.state.chatState.chat.chat_id })
          .then(({ data }) => {
            this.$store.commit('EXEC_CALLBACK_IN_STORE', {
              'callback': () => this.$store.state.chatState.chat.binded_channel = data.chat.binded_channel
            })
          })
          .catch(errorNotification)
          .finally(() => this.channelBindingLoading = false)
      }
    })
  }
}
