
















































































































































































































































































































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import BindChannelByCode, { BindChannelState } from '@/components/chat/BindChannelByCode.vue'
import { ActiveBindMethod } from '@/components/chat/ChannelBinding.vue'
import Placeholders from "@/mixins/placeholders/placeholders";
import { Tag } from "@/includes/types";

import ConfigGroup from 'piramis-base-components/src/components/ConfigGroup/ConfigGroup.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import MiniHelpMessage from 'piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue'
import Tags from "piramis-base-components/src/components/Tags/Tags.vue";

import { Component, Mixins } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { BRAND_TAG } from '@/includes/constants'

@Component({
  'components': {
    BindChannelByCode,
    CenteredColumnLayout,
    PageTitle,
    ConfigGroup,
    ValidationObserver,
    MiniHelpMessage,
    Tags
  },
  data() {
    return {
      ActiveBindMethod,
      MediaEditorMode,
      BRAND_TAG,
    }
  }
})
export default class FaceControlPage extends Mixins(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {
  channel = {
    channelId: ''
  }

  activeMethod = ActiveBindMethod.Self

  channelBindModal = false

  get tariffTags(): Array<Tag> | null {
    return this.getTagsByFieldKey('branding')
  }

  setRecheckTime(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (isNaN(val)) {
        arg.model[arg.key] = 600
      } else {
        if (val < 600) {
          arg.model[arg.key] = 600
        } else {
          arg.model[arg.key] = val
        }
      }
    }

    return arg
  }

  channelBindWarning(value: number | string): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('bind_journal_save_message').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.$store.state.chatState.chat.config.facecontrol_binded_channel_id = value
        })

        this.$store.dispatch('saveChatConfig')
      }
    })
  }

  handleOnChannelBind(data: BindChannelState): void {
    if (data.channel_id) {
      this.channelBindModal = false
      this.channelBindWarning(data.channel_id)
    }
  }

  getBingChannelInfo(): string {
    let chatInfo = ''
    if(this.$store.state.chatState.chat.brand) {
      chatInfo = this.$t('bind_face_control_channel_code_info_2', { '0': `@${ this.$store.state.chatState.chat.brand.botname }`, '1': `https://t.me/${ this.$store.state.chatState.chat.brand.botname }` }).toString()
    } else {
      chatInfo = this.$t('bind_face_control_channel_code_info_2', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
    return chatInfo
  }

  bindFaceControlChannel(): void {
    this.channelBindModal = false
    this.channelBindWarning(Number.parseInt(this.channel.channelId))
  }

  unbind(): void {
    this.$confirm({
      title: this.$t('pi_warning').toString(),
      content: this.$t('unbind_facecontrol_save_message').toString(),
      okText: this.$t('do_save_chat_config').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.$store.state.chatState.chat.config.facecontrol_binded_channel_id = ''
        })
        this.$store.dispatch('saveChatConfig')
        this.activeMethod = ActiveBindMethod.Self
      }
    })
  }
}
